import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import { StaticContentService } from './static-content.service';
import { filter, first, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export const NATIVE_APP_COOKIE = 'isNativeApp';

@Injectable({
  providedIn: 'root',
})
export class InstallAppService {
  private _cookie = inject(CookieService);
  private _static = inject(StaticContentService);

  public nativeAppValueResolved$ = new BehaviorSubject<boolean>(false);
  /**
   * Cookie name variables
   */
  private _registerCookieName: string = '--register-install-app';
  private _notificationCookieName: string = '--notification-install-app';
  private _depositCookieName: string = '--deposit-install-app';
  private _bottomBannerCookieName: string = '--bottom-banner-install-app';
  private _resolvedVariables: boolean;

  /**
   * Variables for show/hide install app block logic (register block, notification message, banner after deposit)
   * @private
   */
  private _showInstallAppRegisterBlock: boolean;

  private _hideInstallAppByCMS: boolean;
  private _isNativeApp: boolean;
  private _showBottomBanner: boolean;

  /**
   * Access from outside
   */
  get showInstallAppRegisterBlock() {
    return this._showInstallAppRegisterBlock;
  }

  get showBottomBanner() {
    return this._showBottomBanner;
  }

  private _showInstallAppNotificationMessage: boolean;

  get showInstallAppNotificationMessage() {
    return this._showInstallAppNotificationMessage;
  }

  private _showInstallAppAfterDepositBanner: boolean;

  get showInstallAppAfterDepositBanner() {
    return this._showInstallAppAfterDepositBanner;
  }

  set showInstallAppAfterDepositBanner(value) {
    this._showInstallAppAfterDepositBanner = value;
  }

  private _canShowAfterDepositBanner: boolean;

  get canShowAfterDepositBanner() {
    return this._canShowAfterDepositBanner;
  }

  private _isEnableNativeApp: boolean;

  get isEnableNativeApp(): boolean {
    return this._isEnableNativeApp;
  }

  get isNativeApp(): boolean {
    return this._isNativeApp;
  }

  get hideInstallAppByCMS(): boolean {
    return this._hideInstallAppByCMS;
  }

  checkIsNativeAppEnabled() {
    this._static
      .item({ slug: 'enabled-native-app' })
      .pipe(
        filter(data => data),
        first(),
        tap(data => {
          // isEnableNativeApp false, meaning that everything related to native app would be hidden
          if (data?.[0]?.Content) {
            this._isEnableNativeApp = !this._cookie.check(NATIVE_APP_COOKIE);
          } else {
            this._isEnableNativeApp = false;
          }
          this.nativeAppValueResolved$.next(this._isEnableNativeApp);
        }),
      )
      .subscribe();
  }

  /**
   * Resolve show variables (check cookie)
   * @private
   */
  public resolveShowVariables() {
    if (!this._resolvedVariables) {
      this._showInstallAppRegisterBlock = !Boolean(this._cookie.get(this._registerCookieName));
      this._showInstallAppNotificationMessage = !Boolean(this._cookie.get(this._notificationCookieName));
      this._canShowAfterDepositBanner = !Boolean(this._cookie.get(this._depositCookieName));
      this._showBottomBanner = !Boolean(this._cookie.get(this._bottomBannerCookieName));
      this._isNativeApp = Boolean(this._cookie.get(NATIVE_APP_COOKIE));
      this._resolvedVariables = true;
    }
  }

  public closeRegisterBlock() {
    this._showInstallAppRegisterBlock = false;
    this._cookie.set(this._registerCookieName, '1');
  }

  public closeNotificationBlock() {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    this._showInstallAppNotificationMessage = false;
    this._cookie.set(this._notificationCookieName, '1', { expires: expirationDate });
  }

  public closeDepositBlock() {
    this._showInstallAppAfterDepositBanner = false;
    this._cookie.set(this._depositCookieName, '1');
  }

  public closeBottomBanner() {
    this._showBottomBanner = false;
    this._cookie.set(this._bottomBannerCookieName, '1');
  }
}
